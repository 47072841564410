@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "hannari";
    src: url("./fonts/hannari/HannariMincho-Regular.otf");

}
*{
    scroll-behavior: smooth;
}
body{
    background-image: url('/public/images/white_wall_hash.png');
}

.underline-hover-effect>div {

    scale: 0;

}

@media (min-width: 768px) {


    .underline-hover-effect:hover>div {
        scale: 1;
    }
    .showBtns:hover>button{
        opacity: 100;
    }
}
#nav-icon4 {
    width: 25px;
    height: 15px;


    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon4 span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: black;
    border-radius: 9px;
    width: 100%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}





#nav-icon4 span:nth-child(1) {
    top: 0px;
   
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(2) {
    top: 8px;
    
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4 span:nth-child(3) {
    top: 16px;
    
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
}

#nav-icon4.open span:nth-child(1) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    top: -2px;
    left: 3px;
}

#nav-icon4.open span:nth-child(2) {
 
    opacity: 0;
}

#nav-icon4.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    
    top: 16px;
    left: 3px;
}